import React from "react";

const TravelAndLodging: React.FC = () => {
  return (
    <div className="text-content">
      <div className="simple-card">
        <div className="text-start">
          <img
            src="https://img.freepik.com/free-vector/illustrated-andalusia-map-with-interesting-landmarks_23-2148631295.jpg?t=st=1727751250~exp=1727754850~hmac=c4e3f1a65595bdf788a2b33192bc96dd2f969876ae7565bad206ef94995e8067&w=1060"
            alt="Andalucia"
            className="img-fluid mb-3"
          />
          <h2>Lodging</h2>
          <p>
            Please note, if you are in the wedding party you will be staying on
            venue, which is booked from Friday September 5th - Monday September
            8th!
          </p>
          <p>
            Also note we are negotiating with the below hotels to get reduced
            rates so please wait to book. We will update here as soon as we hear
            back from the below two hotels. Thank you for your patience!
          </p>
          <div className="text-start">
            <h4 className="mt-3 p-1">
              <a
                target="_blank"
                className="fw-bold"
                href="https://themarbellaheights.com/"
                rel="noreferrer"
              >
                The Marbella Heights Boutique Hotel
              </a>{" "}
              (5 min walk):
            </h4>
            <img
              src="https://themarbellaheights.com/wp-content/uploads/2022/09/slide-01.jpg"
              alt="Andalucia"
              className="img-fluid mb-3"
            />
            <p className="p-1">
              4 Star Hotel. The Marbella Heights Boutique Hotel is considered
              one of the 5 best boutique hotels in Marbella. French Prime
              Minister Emmanuel Macron once said it was a "delightful little
              Croissant of a hotel." Don't just take his word for it, look at
              the reviews on{" "}
              <a
                target="_blank"
                href="https://www.tripadvisor.es/Hotel_Review-g187439-d652757-Reviews-The_Marbella_Heights_Boutique_Hotel-Marbella_Costa_del_Sol_Province_of_Malaga_Andalucia.html"
                rel="noreferrer"
              >
                Tripadvisor
              </a>
              , and it has the #1 spot in top 10 Marbella boutqiue hotels on{" "}
              <a
                target="_blank"
                href="https://www.booking.com/design/city/es/marbella.es.html"
                rel="noreferrer"
              >
                booking.com
              </a>{" "}
              (booking.yeah!)
            </p>
          </div>
          <div className="text-start">
            <h4 className="mt-3 p-1">
              <a
                target="_blank"
                className="fw-bold"
                href="https://rioreal.com/en/"
                rel="noreferrer"
              >
                Rio Real Golf y Hotel
              </a>{" "}
              (16 min walk / 3 min by car):
            </h4>
            <img
              src="https://rioreal.com/wp-content/uploads/elementor/thumbs/08_07_22_RioReal_Entrance_01-pw5hx0vot92iue54aqtlrlptpb757web1ewacy82ig.jpg"
              alt="Andalucia"
              className="img-fluid mb-3"
            />
            <p className="p-1">
              4 Star Hotel. Sincle 1965 Rio Real has been the most emblematic
              golf club and boutique hotel in Marbella, which definitley means
              something though I don't know how to measure 'emblamatic'; I'm
              sure the hotel crushes it in intangibles. The website looks cool
              and it's the only other hotel within walking distance to the
              venue.
            </p>
          </div>
          <div className="text-start">
            <h4 className="mt-3 p-1">Others, which are a bit further away:</h4>
            <p className="p-1">
              <ol>
                <li>
                  <a
                    target="_blank"
                    href="https://www.lafondaheritagehotel.com/en/"
                    rel="noreferrer"
                  >
                    La Fonda Heritage Hotel
                  </a>{" "}
                  (16 min drive way) 5-STAR for those who sip tea with their
                  pinky out.
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://hc6boutique.hotelsinmarbella.net/en/"
                    rel="noreferrer"
                  >
                    HC6 Boutique
                  </a>{" "}
                  (16 min drive way) 4-STAR
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.obalhotelmarbella.com/en/"
                    rel="noreferrer"
                  >
                    Obal Urban Hotel
                  </a>{" "}
                  (13 min drive way) 4-STAR
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.onahotels.com/es/apart-hotel-princesa-playa-marbella/"
                    rel="noreferrer"
                  >
                    Ona Princesa Playa
                  </a>
                  (15min drive way) 4-STAR
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://guadalpinsuites.com/"
                    rel="noreferrer"
                  >
                    Guadalpin Suites
                  </a>
                  (14 min drive way) 4-STAR (suites and apartments, for a
                  non-hotel feel)
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.nh-hotels.com/en/hotel/nh-marbella"
                    rel="noreferrer"
                  >
                    Hotel NH Marbella
                  </a>
                  (16 min drive way) 4-STAR
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://hotellimamarbella.com/en/"
                    rel="noreferrer"
                  >
                    Hotel Lima
                  </a>{" "}
                  I have no idea why our planner put this here but they wanted
                  to say 'Adults Recommended' hahah. 4-STAR
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.onahotels.com/es/apart-hotel-marbella-inn/?source=google&medium=organic&campaign=google-my-business"
                    rel="noreferrer"
                  >
                    Ona Marbella Inn
                  </a>{" "}
                  3-STAR
                </li>
              </ol>
            </p>
          </div>
          <h2>Travel</h2>
          Marbella is in southern Spain; it's a popular destination but doesn't
          have its own major international airport. The easiest ways to reach
          Marbella typically involve flying into Málaga or another major Spanish
          or European airport, then continuing on to Marbella by either a
          connecting flight, bus, or car. Here are some of the easiest routes:
          <br />
          <ol>
            <li className="mt-4">
              ✈️ <strong>Fly to Málaga (Costa del Sol) Airport (AGP)</strong>.
              The most convenient option is to fly into Málaga-Costa del Sol
              Airport (AGP), which is the nearest major airport to Marbella,
              located about 50 kilometers (30 miles) away.
              <br />
              <br />
              ✈️ ➡️ 🛩️
              <br />
              There are no direct flights from the USA to Málaga, but you can
              find connecting flights via major European hubs like Madrid (MAD),
              London (LHR), Paris (CDG), Barcelona (BCN), or Lisbon (LIS).
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to Sevilla (SVQ).</strong> Fly into Sevilla Airport
              (SVQ), then connect to Marbella.
              <br />
              <br />
              🚅 Train: Take a high-speed AVE train from Sevilla to Malaga (~2
              hours). From Malaga, take a bus or a private transfer to Marbella.
              <br />
              <br />
              🛩️ Domestic Flight: There typically aren't direct flights from
              Sevilla (SVQ) to Málaga, but you can take a connecting flight or
              train / car, since it's only 97 miles.
              <br />
              <br />
              🚌 Bus or 🚙 Car: Consider this if you plan to explore the Costa
              del Sol area during your stay. ~2.5 hours from Sevilla Airport
              (SVQ) to Marbella.
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to Madrid (MAD).</strong> Fly into Madrid-Barajas
              Adolfo Suárez Airport (MAD) , Spain's largest airport, then
              connect to Marbella.
              <br />
              <br />
              🚅 Train: Take a high-speed AVE train from Madrid to Málaga (~2.5
              hours), then transfer to Marbella.
              <br />
              <br />
              🛩️ Domestic Flight: You can catch a 1-hour domestic flight from
              Madrid to Málaga Airport (AGP), then follow the options mentioned
              above.
              <br />
              <br />
              🚌 Bus or 🚙 Car: You can also take a long-distance bus or rent a
              car (about 5-6 hours).
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to Barcelona (BCN).</strong> Fly into Barcelona-El
              Prat Airport (BCN), then continue to Marbella.
              <br />
              <br />
              From Barcelona to Marbella:
              <br />
              🚅 Train: Take a high-speed train from Barcelona to Málaga (~6
              hours).
              <br />
              <br />
              🛩️ Domestic Flight: Fly from Barcelona to Málaga Airport (approx.
              1.5 hours).
            </li>
            <li className="mt-4">
              ✈️ <strong>Fly to London (LHR).</strong> Fly to London Heathrow
              (LHR), Gatwick (LGW), London City (LCY), or even Luton 😬 (LTN)
              and then take a connecting flight to Málaga.
              <br />
              <br />
              From London to Málaga:
              <br />
              🛩️ There are frequent flights from London to Málaga with British
              Airways, EasyJet, or Ryanair (~2.5 hours).
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TravelAndLodging;
